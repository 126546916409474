const ADMIN_DASHBOARD = 'dashboard'
const AUTHORIZATION = 'authorization'
const PRODUCTS = 'products'
const RECIPES = 'recipes'
const USERS = 'users'
const HTML_BLOCK = 'html-block'
const MANAGE_PAGES = 'manage-pages'
const FAQ = 'faq'
const CATEGORIES = 'categories'
const SYSTEMS = 'systems'
const BANNER_GROUP = 'banner-group'
const BLOG = 'blog'
const ROLE = 'role'
const USER_ROLE = 'userRole'
const CUSTOMER = 'customer'


export default {
  ADMIN_HOME_PAGE: '/',
  ADMIN_DASHBOARD: `/${ADMIN_DASHBOARD}`,
  AUTHORIZATION: `/${AUTHORIZATION}`,
  ADMIN_USERS: `/${USERS}`,
  ADMIN_FAQ: `/${FAQ}`,
  ADMIN_SYSTEMS: `/${SYSTEMS}`,
  ADMIN_ROLE: `/${ROLE}`,
  ADMIN_USER_ROLE: `/${USER_ROLE}`,
  ADMIN_CATEGORIES: `/${CATEGORIES}`,
  ADMIN_PRODUCT: `/${PRODUCTS}`,
  ADMIN_RECIPES: `/${RECIPES}`,
  ADMIN_HTML_BLOCK: `/${HTML_BLOCK}`,
  ADMIN_BANNER_GROUP: `/${BANNER_GROUP}`,
  ADMIN_MANAGE_PAGES: `/${MANAGE_PAGES}`,
  ADMIN_BLOG: `/${BLOG}`,
  ADMIN_CUSTOMER: `/${CUSTOMER}`
}

import pathAuth from './pathAuth'
import pathSelect from './pathSelect'

export default {
  ...pathAuth,

  ...pathSelect,

  PRODUCT_QUERY: 'productQuery',

  RECIPE_QUERY: 'recipeQuery',

  // SUPPORT_QUERY: 'supportQuery',

  DASHBOARD_QUERY: 'dashboardQuery',

  USERS_QUERY: 'usersQuery',

  GROUP_BANNERS_QUERY: 'groupBannersQuery',

  BANNERS_QUERY: 'bannersQuery',

  CATEGORY_QUERY: 'categoryQuery',

  HTML_BLOCK_QUERY: 'htmlBlockQuery',

  GROUP_HTML_BLOCK_QUERY: 'groupHtmlBlocksQuery',

  MANAGE_PAGES_QUERY: 'managePagesQuery',

  FAQ_QUERY: 'faqQuery',

  RESOURCE_QUERY: 'resourceQuery',

  ROLE_QUERY: 'roleQuery',

  USER_ROLE_QUERY: 'userRoleQuery',

  BLOG_QUERY: 'blogQuery',

  CUSTOMER_QUERY: 'customerQuery',

}